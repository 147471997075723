<template>
  <div class="admin">
    <span style="margin-right: 10px">场地名称</span>
    <el-select v-model="address_id" placeholder="请输入场地名称" style="width: 150px" :disabled="!search">
      <el-option v-for="item in address_list" :key="item.id" :label="item.address_name" :value="item.id"></el-option>
    </el-select>
    <el-button :type="search ? 'primary' : 'warning'" style="margin-left: 30px" @click="searchclick()">
      <span v-if="search">查询</span>
      <span v-else>取消查询</span>
    </el-button>
  </div>


  <el-button type="primary" @click="
  dialogFormVisible = true;
type = 'add';
  " style="float: right; margin-bottom: 30px">添加卡</el-button>
  <el-table :data="tableData" border style="width: 100%; max-height: 70vh; overflow-y: scroll">
    <el-table-column prop="card_id" label="门禁卡号"></el-table-column>
    <el-table-column prop="card_idto" label="充电卡号"></el-table-column>
    <el-table-column prop="system_name" label=" 用户编号"></el-table-column>
    <el-table-column prop="user_name" label=" 用户姓名"></el-table-column>
    <el-table-column prop="mobile" label="用户手机号"></el-table-column>
    <el-table-column prop="address_name" label="绑定场地" width="200px"></el-table-column>

    <el-table-column prop="vehicle_type" label="车辆类型">
      <template #default="scope">
        {{
            vehicleText(scope.row.vehicle_type)
        }}
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="办卡时间"></el-table-column>
    <el-table-column label="是否使用">
      <template #default="scope">
        <el-switch v-model="scope.row.status" active-color="#13ce66" @change="quicked(scope.row)"></el-switch>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="250px">
      <template #default="scope">
        <el-button type="warning" size="small" @click.stop="handleClick(scope.row)">编辑</el-button>
        <el-button type="primary" size="small" @click.stop="handleClick(scope.row, 'addtaocan')">添加套餐</el-button>
        <el-button type="danger" size="small" @click.stop="deleteCarId(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog :title="type == 'add' ? '添加卡' : '编辑卡'" v-model="dialogFormVisible" width="500px">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <div v-if="type == 'revise'">
        <el-form-item label="门禁卡号" :label-width="formLabelWidth" prop="card_id">
          <el-input v-model="form.card_id" autocomplete="off" placeholder="卡号"></el-input>
        </el-form-item>
        <el-form-item label="充电卡号" :label-width="formLabelWidth" prop="card_idto">
          <el-input v-model="form.card_idto" autocomplete="off" placeholder="卡号"></el-input>
        </el-form-item>
        <el-form-item label="是否使用" :label-width="formLabelWidth">
          <el-switch v-model="status" active-color="#13ce66"></el-switch>
        </el-form-item>
      </div>
      <div v-if="type == 'addtaocan'">
        <el-form-item label="场地" :label-width="formLabelWidth" prop="address_id">
          <el-select v-model="form.address_id" placeholder="场地">
            <el-option :label="item.address_name" :value="item.id" v-for="item in address_list" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐" :label-width="formLabelWidth">
          <el-select v-model="form.package_id" placeholder="套餐" @focus="taocanclick()">
            <el-option :label="item.package_name" :value="item.id" v-for="item in taocanlist" :key="item"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="type == 'add'">
        <el-form-item label="门禁卡号" :label-width="formLabelWidth" prop="card_id">
          <el-input v-model="form.card_id" autocomplete="off" placeholder="卡号"></el-input>
        </el-form-item>
        <el-form-item label="充电卡号" :label-width="formLabelWidth" prop="card_idto">
          <el-input v-model="form.card_idto" autocomplete="off" placeholder="卡号"></el-input>
        </el-form-item>
        <el-form-item label="车辆类型" :label-width="formLabelWidth" prop="vehicle_type">
          <el-select v-model="form.vehicle_type" placeholder="车辆类型">
            <el-option :label="item" :value="index" v-for="(item, index) in cartType" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场地" :label-width="formLabelWidth" prop="address_id">
          <el-select v-model="form.address_id" placeholder="场地">
            <el-option :label="item.address_name" :value="item.id" v-for="item in address_list" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐" :label-width="formLabelWidth">
          <el-select v-model="form.package_id" placeholder="套餐" @focus="taocanclick()">
            <el-option :label="item.package_name" :value="item.id" v-for="item in taocanlist" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证" :label-width="formLabelWidth" prop="id_card">
          <el-input v-model="form.id_card" autocomplete="off" placeholder="身份证"></el-input>
        </el-form-item>
        <el-form-item label="名字" :label-width="formLabelWidth" prop="user_name">
          <el-input v-model="form.user_name" autocomplete="off" placeholder="名字"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth" prop="mobile">
          <el-input v-model="form.mobile" autocomplete="off" placeholder="手机号" type="number"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="
  dialogFormVisible = false;
form = {};
        ">取 消</el-button>
        <el-button type="primary" @click="addcurse()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessage } from "element-plus";
import {
  cardList,
  cardAdd,
  cardSave,
  packageAll,
  addPackage,
  changeSwitch,
  deleteCard
} from "../../request/api";
// import { number } from "echarts";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      searchMobile: "",
      searchCarId: "",
      search: true,
      tableData: [],
      dialogFormVisible: false,
      formLabelWidth: "100px",
      address_id: "",
      address_list: [],
      cartType: [],
      taocanlist: [],
      status: false,
      rules: {
        card_id: [
          {
            required: true,
            message: "Please input card_number",
            trigger: "blur"
          }
        ],
        vehicle_type: [
          {
            required: true,
            message: "Please input vehicle_type",
            trigger: "blur"
          }
        ],
        address_id: [
          {
            required: true,
            message: "Please input vehicle_type",
            trigger: "blur"
          }
        ],
        user_name: [
          {
            required: true,
            message: "Please input vehicle_type",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "Please input vehicle_type",
            trigger: "blur"
          },
          {
            min: 11,
            max: 11,
            message: "Length should be 11",
            trigger: "blur"
          }
        ]
      },
      form: {
        card_id: "",
        address_id: "",
        vehicle_type: "",
        mobile: "",
        user_name: "",
        id_card: "",
        package_id: "",
        id: ""
      },
      type: "add",

      formNumber: localStorage.getItem("mobile"),
      showNumber: "13880918155"
    };
  },
  async created() {
    this.address_id = this.$route.query.id
      ? Number(this.$route.query.id)
      : this.address_id;

    this.search = this.address_id ? false : true;
    await this.cartList();
  },
  watch: {
    page() {
      this.cartList();
    },
    pagepage() {
      this.cartList();
    }
  },
  methods: {
    deleteCarId(rows) {
      this.$confirm("确定删除这张卡?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("确定删除", rows)
          deleteCard({
            id: rows.id
          }).then(res => {
            console.log(res)
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.cartList();
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    vehicleText(type) {
      switch (type) {
        case 1:
          return "自行车";
        case 2:
          return "电瓶车";
        case 3:
          return "三轮车";
        case 4:
          return "滑板车";
        case 5:
          return "摩托车";
        default:
          return type;
      }
    },
    changePrivate(row) {
      if (row.id !== undefined) {
        console.log("私有", row.id, row.is_on)
        changeSwitch({
          id: row.id,
          is_on: row.is_on
        }).then(res => {
          console.log("修改", res)
          if (res.code === 200) {
            ElMessage.success({
              message: res.msg,
              type: "success"
            });
          }
        }).catch(err => {
          console.log("请求出错", err)
        })
      }
    },
    rowclick(row) {
      this.$router.push('/Userdetail?id=' + row.user_id)
    },
    quicked(item) {
      this.form.card_id = item.card_id;
      this.form.card_idto = item.card_idto;
      this.status = item.status == 1;
      this.form.id = item.id;
      this.edit();
    },
    taocanclick() {
      packageAll({
        address_id: this.form.address_id,
        vehicle_type: this.form.vehicle_type
      }).then(res => {
        this.taocanlist = res.list.package_list;
      });
    },
    searchclick() {
      this.search = !this.search;
      if (this.search) {
        this.address_id = "";
        this.searchMobile = "";
        this.searchCarId = "";
      }
      this.cartList();
    },
    cartList() {
      return new Promise((resolve, reject) => {
        cardList({
          page: this.page,
          limit: this.pagepage,
          address_id: this.address_id,
          card_id: this.searchCarId,
          mobile: this.searchMobile,
        }).then(res => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.card_list;
          this.address_list = res.list.address_list;
          this.cartType = res.list.vehicles_type;
          this.tableData.forEach(item => {
            item.status = item.status == 1 ? true : false;
          });
        });
      });
    },
    addcurse() {
      if (this.type == "add") {
        this.form.vehicle_type = Number(this.form.vehicle_type);
        delete this.form.id;
        cardAdd(this.form).then(res => {
          if (res.code == 200) {
            ElMessage.success({
              message: res.msg,
              type: "success"
            });
            this.dialogFormVisible = false;
            this.cartList();
            this.form = {};
          } else {
            this.form.vehicle_type = this.form.vehicle_type + "";
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        this.edit();
      } else {
        addPackage({
          package_id: this.form.package_id,
          user_id: this.form.id,
          address_id: this.form.address_id
        }).then(res => {
          if (res.code == 200) {
            ElMessage.success({
              message: res.msg,
              type: "success"
            });
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
    handleClick(item, type) {
      console.log(item);
      this.dialogFormVisible = true;
      this.form.card_id = item.card_id;
      this.form.card_idto = item.card_idto;
      this.status = item.status == 1;
      this.form.id = item.id;
      console.log(type);
      if (type) {
        this.type = "addtaocan";
        this.form.vehicle_type = item.vehicle_type;
        this.form.id = item.user_id;
      } else {
        this.type = "revise";
      }
    },
    edit() {
      this.status = this.status == true ? 1 : 2;
      console.log("编辑")
      cardSave({
        id: this.form.id,
        card_id: this.form.card_id,
        card_idto: this.form.card_idto,
        status: this.status
      }).then(res => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.msg,
            type: "success"
          });
          this.dialogFormVisible = false;
          this.cartList();
          this.form = {};
        } else {
          ElMessage.error(res.msg);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.cell {
  text-align: center;
}
</style>


